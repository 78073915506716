import _minimist from "minimist";
import _browserResolve from "browser-resolve";
import _path from "path";
import _fs from "fs";
import _glob from "glob";
import _chokidar from "chokidar";
import _append from "utilise/append";
import _values from "utilise/values";
import _keys from "utilise/keys";
import _def from "utilise/def";
import _key from "utilise/key";
import _is from "utilise/is";
import _lo from "utilise/lo";
import _by from "utilise/by";
import _za from "utilise/za";
import _log from "utilise/log";
import _err from "utilise/err";
import _minimatch from "minimatch";
import _process from "process";
var exports = {};
var process = _process;

// -------------------------------------------
// Loads resources from the /resources folder
// -------------------------------------------
// TODO: rename loader
exports = function loader(ripple, {
  dir = ".",
  watch = isNonProd(),
  pattern = "/**/!(*test).{css,js}",
  autoload = "resources",
  autolink = "/resources/components/**/!(*test).{css,js}",
  aliases = {}
} = {}) {
  log("creating", {
    watch
  });
  glob(autolink, {
    root: dir
  }).map(path => ripple.link(rel(dir, path), rtype(ripple, path).shortname(path)));

  const {
    r = "",
    resdirs = r
  } = _minimist(process.argv.slice(2)),
        load = register(ripple, dir),
        folders = resdirs.split(",").concat(resolve(dir, autoload)).filter(Boolean).map(d => resolve(d)).map(append(pattern));

  ripple.watcher = chokidar.watch(folders, {
    ignored: /\b_/
  }).on("error", err).on("add", load).on("change", load).on("ready", async () => {
    if (!watch) ripple.watcher.close();
    await Promise.all(values(ripple.resources).map(res => res.headers.loading)).catch(err);
    def(ripple, "ready", true);
    ripple.emit("ready");
  });

  ripple.load = (name, alias) => {
    if (ripple.resources[name]) return ripple.resources[name];
    const path = bresolve(ripple.aliases.dst[name] || name, resolve(dir, "foo")),
          canonical = rel(dir, path);

    if (alias) {
      ripple.link(canonical, alias);
    } else if (!ripple.aliases.dst[name] && name != canonical) {
      ripple.link(name, canonical);
    }

    return load(path);
  }; // TODO: move back to core as chainable 


  ripple.resource = (name, body, headers) => {
    // is.str(body)
    //   ? ripple.load(body, name)
    ripple(name, body, headers);
    return ripple;
  };

  return ripple;
};

const register = (ripple, dir) => path => {
  const type = rtype(ripple, path);
  if (!exists(path)) throw new Error(`no such resource at ${path}`);
  if (!type) throw new Error(`could not understand how to load resource at ${path}`); // TODO: should probably let loaders do this

  delete {}[path];
  return type.load({
    name: rel(dir, path),
    headers: {
      path
    }
  });
};

const rtype = (ripple, path) => values(ripple.types).filter(d => d.ext).sort(za("ext.length")).find(({
  ext
}) => minimatch(basename(path), ext));

const rel = (dir, path) => "./" + relative(dir, path).replace(/\\/g, "/");

const bresolve = (module, parent) => _browserResolve.sync(module, {
  filename: parent
});

function isNonProd() {
  return lo("production") != "prod" && lo("production") != "production";
}

const {
  resolve,
  relative,
  basename
} = _path,
      exists = _fs.existsSync,
      glob = _glob.sync,
      chokidar = _chokidar,
      append = _append,
      values = _values,
      keys = _keys,
      def = _def,
      key = _key,
      is = _is,
      lo = _lo,
      by = _by,
      za = _za,
      log = _log("[ri/resdir]"),
      err = _err("[ri/resdir]"),
      minimatch = _minimatch,
      extname = path => [""].concat(path.split(".").slice(1)).join(".");

export default exports;